<template>
  <div id="bottomMenuBox">
    <div class="itemInfo">
      <p>默认字体/图标颜色：</p>
      <div class="right">
        <el-input v-model="defaultColor" disabled></el-input>
        <el-color-picker v-model="defaultColor"></el-color-picker>
      </div>
    </div>
    <div class="itemInfo">
      <p>选中字体/图标颜色：</p>
      <div class="right">
        <el-input v-model="activeColor" disabled></el-input>
        <el-color-picker v-model="activeColor"></el-color-picker>
      </div>
    </div>
    <div class="itemInfo">
      <p>效果示意图：</p>
      <div class="right">点击选中菜单，可对选中菜单进行编辑；双击可删除菜单,可拖动对菜单进行排序</div>
    </div>
    <div class="itemInfo">
      <p></p>
      <div class="right">
        <!-- 字体图标 -->
        <vuedraggable v-model="defaultArr" v-bind="{ animation: 200, group: 'description', disabled: false, ghostClass: 'ghost', scroll: true }" class="movebox">
          <transition-group>
            <div
              v-for="(item, index) in defaultArr"
              @dragend="ondragend(index)"
              @click="chooseUpdate(item, index)"
              :key="index"
              @dblclick="dbDelete(index)"
              class="iconBox allIconList"
              :style="{ color: defaultColor }"
            >
              <i :class="['iconfont', activeindex == index ? item.selectIcon : item.icon]" :style="{ color: activeindex == index ? activeColor : '' }"></i>
              <div :style="{ color: activeindex == index ? activeColor : '' }">{{ item.text }}</div>
            </div>
          </transition-group>
        </vuedraggable>

        <div class="iconBox addIcon" @click="addDefaultArr">+</div>
        <div class="tips">最多支持5个底部菜单</div>
      </div>
    </div>
    <div class="itemInfo">
      <p>文字描述：</p>
      <div class="right">
        <el-input :maxlength="4" v-model="activeItem.text"></el-input>
        <div class="tips2">不能超过四个字</div>
      </div>
    </div>
    <div class="itemInfo">
      <p>默认图标：</p>
      <div class="right">
        <el-input disabled v-model="activeItem.icon"></el-input>
        <el-button type="primary" @click="chooseIcon(0)">选择图标</el-button>
      </div>
    </div>
    <div class="itemInfo">
      <p>选中图标：</p>
      <div class="right">
        <el-input disabled v-model="activeItem.selectIcon"></el-input>
        <el-button type="primary" @click="chooseIcon(1)">选择图标</el-button>
      </div>
    </div>
    <div class="itemInfo">
      <p>跳转页面：</p>
      <div class="right">
        <el-input disabled v-model="activeItem.path"></el-input>
        <el-button type="primary" @click="toPageFlag = true">选择页面</el-button>
      </div>
    </div>
    <Preservation @preservation="preservation" :addPage="$route.path === '/addPages/bottomMenu' ? 1 : ''"></Preservation>
    <el-dialog title="选择图标" :visible.sync="dialogVisible" width="52%" class="mt10vh" :before-close="handleClose">
      <div>
        <el-input placeholder="请输入icon名称" v-model="searcIcon" class="input-with-select">
          <el-button @click="getFont(1)" style="color: #fff; background-color: #409eff; border-color: #409eff" type="primary" slot="append">搜索</el-button>
        </el-input>
      </div>
      <div class="iconListBox">
        <div class="iconLbox allIconList">
          <div
            v-for="(item, index) in showIcon"
            :key="index"
            @click="iconClick(item, index)"
            @mouseenter="hoverDialogIcon = index"
            @mouseleave="hoverDialogIcon = -1"
            :class="[activeDialogIcon == index ? 'active' : '', hoverDialogIcon == index ? 'hover' : '']"
          >
            <i :class="['iconfont', 'icon' + item.font_class]"></i>
            <div>{{ item.name }}</div>
          </div>
        </div>
        <Paging class="pageing" ref="pageIng" :style="1" :total="total" :page="page" :pageNum="pageNum" @updatePageNum="updateData"></Paging>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sureIcon">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="跳转路径" :visible.sync="toPageFlag" width="52%">
      <div>
        <ToPage ref="toPage" @getPage="getPageRes"></ToPage>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import vuedraggable from 'vuedraggable';
import Paging from '../../../components/paging';
import ToPage from '../toPage/index';
import Preservation from '@/components/preservation';
export default {
  data() {
    return {
      dialogVisible: false,
      searcIcon: null,
      isSearch: false,
      defaultColor: '#333',
      activeColor: '#409EFF',
      defaultArr: [
        // { text: 'Show', icon: 'iconbiaoqiankuozhan_faxian-139', selectIcon: 'iconbiaoqiankuozhan_faxian-139', path: '选择页面' },
        // { text: '商城', icon: 'iconbiaoqiankuozhan_shouye-156', selectIcon: 'iconbiaoqiankuozhan_shouye-156', path: '选择页面' },
        // { text: '分类', icon: 'iconbiaoqiankuozhan_fenlei-127', selectIcon: 'iconbiaoqiankuozhan_fenlei-127', path: '选择页面' },
        // { text: '我的', icon: 'iconbiaoqiankuozhan_wode-260', selectIcon: 'iconbiaoqiankuozhan_wode-260', path: '选择页面' }
      ],
      page: 1,
      pageNum: 48,
      total: 0,
      showIcon: [],
      activeItem: {
        text: '',
        icon: '',
        selectIcon: '',
        path: '',
      },
      activeindex: 0,
      activeDialogIcon: -1,
      activeDialogIconID: -1,
      hoverDialogIcon: -1,
      style: -1,
      toPageFlag: false,
    };
  },
  components: {
    Paging,
    ToPage,
    vuedraggable,
    Preservation,
  },
  methods: {
    // 弹框关闭时清空搜索
    handleClose() {
      this.searcIcon = null;
      this.dialogVisible = false;
    },
    // 拖拽触发
    ondragend(index) {
      let that = this;
      setTimeout(() => {
        this.activeindex = this.defaultArr.findIndex(item => item == that.activeItem);
      }, 10);
    },
    // 分页触发
    updateData(val, status) {
      if (status == 0) {
        this.pageNum = val;
      } else {
        this.page = val;
      }
      if (!this.isSearch) {
        this.getFont();
      } else {
        // 是否搜索 是否是分页触发
        this.getFont(1, 1);
      }
    },
    // 点击选择ICon
    chooseIcon(style) {
      this.getFont();
      this.dialogVisible = true;
      this.style = style;
    },
    // 获取所有icon
    getFont(style, isPage) {
      //           keywords: '管理',
      this.activeDialogIcon = -1;
      let that = this;
      let obj = {};
      if (style == 1) {
        if (!isPage) {
          this.page = 1;
        }
        this.isSearch = true;
        obj = {
          key: 'glyphs',
          keywords: this.searcIcon,
          page: that.page,
          rows: that.pageNum,
        };
      } else {
        obj = {
          key: 'glyphs',
          page: that.page,
          rows: that.pageNum,
        };
      }
      this.$axios.post(this.$api.shopDiy.setInfo, obj).then(res => {
        if (res.code == 0) {
          if (!that.isSearch) {
            that.total = res.result.info.total_number;
            that.showIcon = res.result.info.datas;
            that.activeDialogIcon = that.getIconIndex(that.showIcon);
          } else {
            if (!that.searcIcon) {
              that.total = res.result.info.total_number;
              that.showIcon = res.result.info.datas;
              that.activeDialogIcon = that.getIconIndex(that.showIcon);
              return;
            }
            that.total = res.result.total_number;
            that.showIcon = res.result.datas;
            that.activeDialogIcon = that.getIconIndex(that.showIcon);
          }
        }
      });
    },
    // 双击删除事件
    dbDelete(index) {
      if (this.defaultArr.length <= 2) {
        this.$message.warning('最少需要两个底部菜单');
        return;
      }
      this.$confirm('确认删除此项？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(res => {
          if (index < this.defaultArr.length - 1) {
            this.activeindex = index;
          } else {
            this.activeindex = 0;
          }
          this.defaultArr.splice(index, 1);
        })
        .catch(err => {});
    },
    // 点击添加默认项
    addDefaultArr() {
      if (this.defaultArr.length >= 5) {
        this.$message({
          message: '最多只可添加五个底部菜单',
          type: 'warning',
        });
        return;
      }
      this.defaultArr.push({ text: '未命名', icon: 'iconbiaoqiankuozhan_wode-220', selectIcon: 'iconbiaoqiankuozhan_wode-220', path: '选择页面' });
    },
    // 点击选择修改某个底部菜单
    chooseUpdate(item, index) {
      this.activeItem = item;
      this.activeindex = index;
    },
    // 点击确定icon
    sureIcon() {
      if (this.activeDialogIcon == -1) {
        this.searcIcon = null;
        this.dialogVisible = false;
        return;
      }
      if (this.style == 0) {
        this.activeItem.icon = 'icon' + this.showIcon[this.activeDialogIcon].font_class;
      } else if (this.style == 1) {
        this.activeItem.selectIcon = 'icon' + this.showIcon[this.activeDialogIcon].font_class;
      }
      this.searcIcon = null;
      this.dialogVisible = false;
    },
    // 点击保存配置
    preservation() {
      let flag = false;
      if (!this.defaultColor) {
        this.$message.warning('图标默认颜色不能为空');
        return;
      }
      if (!this.activeColor) {
        this.$message.warning('图标默认颜色不能为空');
        return;
      }
      for (let i = 0; i < this.defaultArr.length; i++) {
        if (this.defaultArr[i].path == '选择页面') {
          this.$message.warning(`请选择第${i + 1}个底部菜单的跳转路径`);
          return;
        }
        flag = true;
      }
      if (!flag) {
        return;
      }
      let that = this;
      this.$axios
        .post(this.$api.shopDiy.setSetUp, {
          tabbar_bg: '#ffffff',
          tabbar_color: that.defaultColor,
          tabbar_select_color: that.activeColor,
          tabbar_list: that.defaultArr,
        })
        .then(res => {
          if (res.code == 0) {
            that.$message({
              message: '保存成功',
              type: 'success',
            });
            return;
          }
          that.$message({
            message: '保存失败',
            type: 'error',
          });
        });
    },
    getPageRes(item) {
      this.activeItem.path = item.wxroute;
      this.toPageFlag = false;
    },
    // 选中icon
    iconClick(item, index) {
      this.activeDialogIcon = index;
      this.activeDialogIconId = item.icon_id;
    },
    // 查询选中icon的位置
    getIconIndex(list) {
      return list.findIndex(item => item.icon_id == this.activeDialogIconId);
    },
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.pageIng.changeSize();
        });
      }
    },
    toPageFlag(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.toPage.getPageArr();
        });
      }
    },
    activeindex(val) {
      console.log(val);
      this.activeItem = this.defaultArr[val];
    },
  },
  created() {
    let that = this;
    this.$axios.post(this.$api.shopDiy.bottomSet, {}).then(res => {
      if (res.code == 0) {
        let data = res.result;
        that.defaultColor = data.tabbar_color;
        that.activeColor = data.tabbar_select_color;
        if (data.tabbar_list && data.tabbar_list.length != 0) {
          that.defaultArr = data.tabbar_list;
        }
        that.chooseUpdate(that.defaultArr[0], 0);
      }
    });
  },
};
</script>

<style lang="less" scoped>
.mt10vh {
  /deep/ .el-dialog {
    margin-top: 5vh !important;
  }
}
#bottomMenuBox {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 50px 0 0 50px;
  font-size: 14px;
  overflow-y: auto;
  .itemInfo {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    p {
      width: 150px;
      text-align: right;
      margin-right: 20px;
    }
    .right {
      position: relative;
      display: flex;
      .movebox {
        & > span {
          display: flex;
        }
      }
      .preservation {
        width: 100px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        background-color: #409eff;
        color: #fff;
        border-radius: 5px;
        cursor: pointer;
        user-select: none;
      }
      .el-input {
        width: 600px;
      }
      .el-color-picker {
        position: absolute;
        width: 100px;
        right: 0;
        bottom: 0;
        & /deep/ .el-color-picker__trigger {
          width: 100px;
        }
      }
      .el-button {
        position: absolute;
        right: 0;
        bottom: 0;
      }
      .addIcon {
        margin: 0 20px;
      }
      .tips {
        font-size: 12px;
        line-height: 50px;
        user-select: none;
      }
      .tips2 {
        margin-left: 20px;
        font-size: 12px;
        line-height: 40px;
        user-select: none;
      }
      .iconBox {
        width: 50px;
        height: 50px;
        border: 1px solid #e4e4e4;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        cursor: pointer;
        i {
          font-size: 20px;
        }
        div {
          font-size: 12px;
        }
      }
    }
  }
  .iconListBox {
    display: flex;
    flex-direction: column;
    .pageing {
      align-self: flex-end;
    }
    .iconLbox {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      & > div {
        cursor: pointer;
        flex: 0 0 100px;
        height: 80px;
        margin: 9px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        // border: 1px solid #ccc;
        i {
          font-size: 30px;
        }
      }
      .active {
        color: #409eff;
        border: 1px solid #409eff;
      }
      .hover {
        background-color: rgba(64, 158, 255, 0.1);
      }
    }
  }
}
</style>
